import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createReview,
  getAllReviewsByEmail,
  getReviewById,
  deleteReviewByRevieweeEmail,
} from "../../apiControllers/reviewApiController";
const text = require("./Resources/instructions.json");

/**
 * SelfAssessmentContainer component handles the creation of a self-assessment review.
 *
 * @returns {JSX.Element} The SelfAssessmentContainer component.
 */
const SelfAssessmentContainer = () => {
  const [payload, setPayload] = useState({});
  const [selfReview, setSelfReview] = useState({ status: null });
  const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();

  /**
   * useEffect hook to initialize the payload and selfReview with review details when the component mounts.
   */
  useEffect(() => {
    const getAndSetPayloadData = async (review_id) => {
      try {
        let response = await getReviewById(review_id);

        if (response) {
          setPayload({
            reviewee_email_address: localStorage.getItem("userEmail"),
            reviewer_first_name: response.reviewer_first_name,
            reviewer_middle_names: response.reviewer_middle_names,
            reviewer_last_name: response.reviewer_last_name,
            reviewer_email_address: response.reviewer_email_address,
            company_name: response.company_name,
            relationship_to_reviewee: response.relationship_to_reviewee,
            review_period_start: response.review_period_start,
            review_period_end: response.review_period_end,
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Executes an API call to retrieve all reviews by email and sets the self review state.
     *
     * @return {Promise<void>} - A promise that resolves once the API call is complete and the self review state is set.
     */
    const apiCall = async () => {
      console.log("API CALL");
      try {
        let responseExisting = await getAllReviewsByEmail(
          localStorage.getItem("userEmail")
        );
        const isSelfReview = (review) => review.type === "self";
        const apiSelfReview = responseExisting.find(isSelfReview);
        if (apiSelfReview) {
          setSelfReview(apiSelfReview);
          getAndSetPayloadData(apiSelfReview.reviewId);
        }
      } catch (e) {
        console.error(e);
        setSelfReview({ status: null });
      }

      setDataLoaded(true);
    };

    if (!dataLoaded) {
      apiCall();
    }

    console.log("sr", selfReview);
    console.log("status", selfReview.status);
  }, [selfReview]);

  /**
   * Function to create a self-review using the payload.
   * On success, navigates to the self-assessment survey page.
   */
  const createSelfReview = async () => {
    try {
      let response = await createReview(payload);
      navigate(`/self-assessment-survey?reviewId=${response.data.review_id}`);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Navigates to the self-assessment survey page with the ID of the existing self-review.
   *
   * @return {void} This function does not return anything.
   */
  const continueReview = () => {
    navigate(`/self-assessment-survey?reviewId=${selfReview.reviewId}`);
  };

  /**
   * Asynchronously redoes a review by deleting the existing review with the given payload,
   * creating a new review with the same payload, and navigating to the self-assessment survey
   * page with the ID of the newly created review.
   *
   * @return {Promise<void>} A Promise that resolves when the review is successfully redone.
   * @throws {Error} If there is an error deleting or creating the review.
   */
  const redoReview = async () => {
    try {
      let deleteResponse = await deleteReviewByRevieweeEmail(payload);
      console.log(deleteResponse);

      let createResponse = await createReview(payload);
      console.log(createResponse);
      navigate(
        `/self-assessment-survey?reviewId=${createResponse.data.review_id}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Renders a button based on the given status.
   *
   * @param {string} status - The status of the button.
   * @return {JSX.Element} The rendered button component.
   */
  const renderButtonOnStatus = (status) => {
    switch (status) {
      case "Requested":
        return (
          <button
            className="self-assessment-btn btn-warning"
            onClick={continueReview}
          >
            {text.continueReview}
          </button>
        );
      case "Completed":
        return (
          <button
            className="self-assessment-btn btn-secondary"
            onClick={redoReview}
          >
            {text.redoReview}
          </button>
        );
      default:
        return (
          <button
            className="self-assessment-btn btn-secondary"
            onClick={createSelfReview}
          >
            {text.button}
          </button>
        );
    }
  };

  return (
    <div className="instructions-container">
      <div className="self-assessment-alignment">
        <div className="self-assessment-instructions">
          <h1 className="instructions-title">{text.title}:</h1>
          {text.sections.map((section, index) => (
            <div key={index} className="instructions-text">
              <p>
                {section.title}: {section.content}
              </p>
              {section.scale && (
                <ul className="self-assessment-rating-list">
                  {section.scale.map((item, idx) => (
                    <li key={idx}>
                      {item.rating} - {item.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <p className="instructions-text">{text.closing}</p>
        </div>
        {selfReview && (
          <div className="self-assessment-buttons">
            {renderButtonOnStatus(selfReview.status)}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelfAssessmentContainer;
